import Service from '@/helpers/service'

export default {

    get() {
        return Service().get('GeneralSetting/mail-configuration');
    },
    update(id, params) {
        return Service().post('GeneralSetting/mail-configuration/update/'+id, params);
    },
    testConn() {
        return Service().post('GeneralSetting/mail-configuration/connection-test');
    },
}